import React from "react";
import { Card, CardBody, Image } from "react-bootstrap";

const Cards = ({ imgUrl, alts, head, headDesc, desc }) => {
  return (
    <>
      <Card>
        <CardBody>
          <Image className="icons" src={imgUrl} alt={alts} />
          {head && <h5>{head}</h5>}
          {headDesc && <p className="text-magenta">{headDesc}</p>}
          <p className="mb-0"><span dangerouslySetInnerHTML={{__html:desc}}/></p>
        </CardBody>
      </Card>
    </>
  );
};

export default Cards;


// import React from "react";

// const Cards = ({ imgUrl, alts, head, headDesc, desc }) => {
//   return (
//     <>
//         <div className="card">
//           <div className="card-body">
//             <img className="icons" src={imgUrl} alt={alts} />
//             {head && <h5>{head}</h5>}
//             {headDesc && <p className="text-magenta">{headDesc}</p>}
//             <p className="mb-0"><span dangerouslySetInnerHTML={{__html:desc}}/></p>
//           </div>
//         </div>
//     </>
//   );
// };

// export default Cards;
