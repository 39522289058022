import React, { useState, useEffect, useRef, Fragment } from "react";
import Slider from "react-slick";
import "./TabsScroll.scss";
// import { TabsScrollData } from "./TabsScrollData.js";
import VideoAutoPlay from "../Video/VideoAutoPlay.js";
import VideoWindowPlay from "../Video/VideoWindowPlay.js";

const TabsScroll = ({colCls,TabScrollData, addCls, count1,count2, count3, count4,count5}) =>{
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  let sliderRef1 = useRef(null);
  let sliderRef2 = useRef(null);
 
  useEffect(() => {
    setNav1(sliderRef1);
    setNav2(sliderRef2);
  }, []);

  const settings = {
    arrows:false,
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "0px",
    slidesToShow: count1,
    swipeToSlide: true,
    focusOnSelect: true,
    speed: 500,
    responsive: [
      {
        breakpoint: 992,
        settings: {
        slidesToShow: count2
        }
      },
      {
        breakpoint: 768,
        settings: {
        slidesToShow: count3
        }
      },
      {
        breakpoint: 480,
        settings: {
        slidesToShow: count4
        }
      },
      {
        breakpoint: 320,
        settings: {
        slidesToShow: count5
        }
      }
    ]
  };
  const setting = {
    fade: true,
    swipeToSlide: true,
    focusOnSelect: true,
    arrows:false,
    speed: 500,
  }

  return (
    <>     
      <div className="container">
        <div className="multitab_content nav-tabs">
          <div className="row justify-content-center">
            <div className={colCls}>
                {TabScrollData.map((tabItem, tabIndex) => (
                      <div className={`slider-container ${addCls}`} key={tabIndex}>
                          <div className="heading-title text-center">
                                <h2 className="under-line mb-3">{tabItem.title}</h2>
                          </div>
                          {tabItem.desc &&
                              <p className="text-center fn-4 mb-5">{tabItem.desc}</p>
                          }
                          <div className="tabs-left">
                                <Slider
                                  asNavFor={nav1}
                                  ref={(slider) => (sliderRef2 = slider)}
                                  {...settings}
                                >
                                  {tabItem.AllTabData.map((topItem, topIndex) => (
                                    <div className="topdiv" key={topIndex}>
                                      <div className="icons">
                                        <span className={`img ${topItem.tabIcon}`}></span>
                                        {/* <img src={topItem.tabIcon} alt={topItem.tabName} /> */}
                                        {/* <span dangerouslySetInnerHTML={{ __html: topItem.tabIcon }}></span> */}
                                      </div>
                                      <div className="text">{topItem.tabName}</div>
                                    </div>
                                  ))}
                                </Slider>
                          </div>
                          <div className="tab-content">
                            <Slider
                            asNavFor={nav2}
                            ref={(slider) => (sliderRef1 = slider)}
                            {...setting}
                            >
                              {tabItem.AllTabData.map((innerItem, innerIndex) => (
                                <div className="tab-panel" key={innerIndex}>
                                  {innerItem.navList.map((item, nestedIndex) => (
                                    <Fragment key={nestedIndex}>
                                        {item.videoUrl ? (
                                            item.videoAuto === true ? (
                                              <VideoAutoPlay 
                                                videoSource={item.videoUrl} 
                                                videoPoster={item.imgUrl} 
                                                videoShadow="" 
                                                radius="radius-1" 
                                              />
                                            ) : (
                                              <VideoWindowPlay 
                                                videoSource={item.videoUrl} 
                                                videoPoster={item.imgUrl} 
                                                videoShadow="" 
                                                radius="radius-1" 
                                              />
                                            )
                                        )
                                        :(
                                        <img className="img-fluid" src={item.imgUrl} alt={item.alt ? item.alt : item.title} key={nestedIndex} />
                                      )}
                                    </Fragment>
                                  ))}
                                </div>
                              ))}
                            </Slider>
                          </div>
                      </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TabsScroll;
