import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faLinkedinIn, faXTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { Link } from 'react-router-dom';

export const SocialNetwork = ({socialNetwork}) => {
  return (
    <>
        <ul className={`list-unstyled ${socialNetwork}`}>
            <li><Link to="https://twitter.com/qkrhr_official" target='_blank' className="icoTwitter" title="Twitter"><FontAwesomeIcon icon={faXTwitter}/></Link></li>
            <li><Link to="https://www.facebook.com/Qkrbiz/" target='_blank' className="icoFacebook" title="Facebook"><FontAwesomeIcon icon={faFacebookF}/></Link></li>
            <li><Link to="https://www.linkedin.com/company/qkrbiz/" target='_blank' className="icoLinkedin" title="LinkedIn"><FontAwesomeIcon icon={faLinkedinIn}/></Link></li>
            <li><Link to="https://www.youtube.com/@Qkrbiz/" target='_blank' className="icoYoutube" title="YouTube"><FontAwesomeIcon icon={faYoutube}/></Link></li>
        </ul>	
    </>
  )
}
