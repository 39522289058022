import React, { Suspense, useEffect } from "react";
import { useLocation } from "react-router-dom";
import "./App.scss";
import Header from "./shared/Header/Header";
import Footer from "./shared/Footer/Footer";
import Loading from "./utility/Loading/Loading";
import AppRoutes from "./AppRoutes";

function App() {

  const location = useLocation();

  const isNotAllowedPaths = [
    '/hrms-software/core-hr',
    '/hrms-software/employee-management',
    '/hrms-software/employee-self-service-portal',
    '/hrms-software/hr-document-management-system',
    '/hrms-software/hr-workflow-management',
    '/hrms-software/hr-data-analytics',
    '/hrms-software/field-force-automation',
    '/hrms-software/leave-and-attendance-management',
    '/hrms-software/leave-management-system',
    '/hrms-software/shift-management-system',
    '/hrms-software/time-and-attendance-system',
    '/hrms-software/payroll-management-system',
    '/hrms-software/expense-management',
    '/hrms-software/employee-engagement',
    '/hrms-software/tasks',
    '/qkrsales/salesmanagement',
    '/qkrsales/accountsreceivable',
    '/qkrsales/billingpayment',
    '/qkrsales/orderexecution',
    '/qkrsales/invoicing',
    '/qkrsales/tenderdeposit',
    '/qkrsales/salesperformance',
    '/hr-management'
  ];

  const isNotDisplayHeader = isNotAllowedPaths.includes(location.pathname);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
 
  return (
    <>
      {!isNotDisplayHeader && (
          <Header />
      )}

      <Suspense fallback={<Loading />}>
          <AppRoutes/>
      </Suspense>
      <Footer />
    </>
  );
}

export default App;